import requestApiGuest from '@Service/RequestApiMiddlewareGuest';
import requestApiMember from '@Service/RequestApiMiddlewareMember';
import requestApiMemberNew from '@Service/RequestApiMiddlewareMemberNew';

const register = '/register';
const login = '/login';
const logout = '/logout';
const forgotPassword = '/forgot-password';
const customer = '/customer';
const customerDesign = '/customer/design';

export default {
  register() {
    return requestApiGuest.get(`${register}`);
  },
  login() {
    return requestApiGuest.get(`${login}`);
  },
  logout() {
    return requestApiMember.get(`${logout}`);
  },
  forgotPassword() {
    return requestApiGuest.get(`${forgotPassword}`);
  },
  getCustomer() {
    return requestApiMember.get(`${customer}`);
  },
  updateCustomer() {
    return requestApiMember.put(`${customer}`);
  },
  async getCustomerDesigns() {
    return requestApiMemberNew().then(instance => {
      // Now you can use this instance for making requests
      return instance.get(`${customerDesign}`);
    });
  },
  createOrUpdateCustomerDesign(data) {
    return requestApiMember.post(`${customerDesign}`, data);
  },
  getCustomerDesignDetail(productId) {
    return requestApiMember.get(`${customerDesign}/${productId}`);
  },
  deleteCustomerDesign(productId) {
    return requestApiMember.delete(`${customerDesign}/${productId}`);
  }
};

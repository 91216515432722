import Vue from 'vue';
import Vuex from 'vuex';
import app from './app';
import designModule from './designModule';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null
    }
  },
  getters: {
    user(state) {
      return state.user;
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      commit('SET_LOGGED_IN', user !== null);
      if (user) {
        user.getIdToken().then(function(idToken) {
          commit('SET_USER', {
            displayName: user.displayName,
            email: user.email,
            photo: user.photoURL,
            token: idToken
          });

          localStorage.setItem('userToken', idToken);
        });
      } else {
        commit('SET_USER', null);
      }
    }
  },
  modules: {
    app,
    designModule
  }
});

import axios from 'axios';

const token = localStorage.getItem('userToken');
let headers = {
  'Content-Type': 'application/json, multipart/form-data',
  'Access-Control-Allow-Origin': '*',
  Accept: 'application/json'
};

if (token) {
  headers.Authorization = `Bearer ${token}`;
}

export default axios.create({
  baseURL: process.env.VUE_APP_API_ROOT + '/' + process.env.VUE_APP_API_VERSION,
  headers: headers
});

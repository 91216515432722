import axios from 'axios';

const getTokenFromLocalStorage = () => {
  return new Promise(resolve => {
    const token = localStorage.getItem('userToken'); // Replace 'your_token_key' with the actual key for your token
    resolve(token);
  });
};

const requestApiMemberNew = async () => {
  const token = await getTokenFromLocalStorage();

  // Create an instance of Axios with the retrieved token
  const instance = axios.create({
    baseURL:
      process.env.VUE_APP_API_ROOT + '/' + process.env.VUE_APP_API_VERSION,
    headers: {
      'Content-Type': 'application/json, multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
      // Add any other custom headers as needed
    }
  });

  return instance;
};

export default requestApiMemberNew;
